import { Injectable } from '@angular/core';

import { HttpService } from "../http/http.service"
import { NavController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  auth: boolean = false

  constructor(private http: HttpService, private navCtrl: NavController) {}

  login(username: string, password: string, redirectToURL: string) {
    return new Promise(async (resolve, reject) => {
      await this.http.loginUser(username, password).toPromise().then(response => {

        localStorage.setItem('currentUser', JSON.stringify(response.access_token));
        currentUserSubject.next(response);
        this.navCtrl.navigateRoot(redirectToURL)
        //console.log(localStorage.getItem('currentUser'));
      }, err => {
        if(err.status == 504){
          console.log(err)
          reject('connection')
        }
        if(err.status == 401){
          reject('login')
        }
      })
    });
  }

  logout() {
    //console.log('loged out')
    localStorage.clear()
    this.navCtrl.navigateRoot('/login')
  }

  get authenticated(): boolean {
    return this.auth
  }


}

export interface User {
  username: string
  password: string
}