import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { BarcodeScanner } from "@ionic-native/barcode-scanner/ngx";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginGuard } from "./core/guards/login.guard"
import { HttpService, DEFAULT_TIMEOUT } from './core/services/http/http.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule, ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })],
  providers: [
    StatusBar,
    SplashScreen,
    BarcodeScanner,
    LoginGuard,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    [{provide: HTTP_INTERCEPTORS, useClass: HttpService, multi: true}],
    [{provide: DEFAULT_TIMEOUT, useValue: 5000}]
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
