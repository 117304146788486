import { Injectable, Inject, InjectionToken } from '@angular/core';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable } from 'rxjs';
import { timeout } from "rxjs/operators";

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable({
  providedIn: 'root'
})
export class HttpService implements HttpInterceptor {

  constructor(@Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number,
    private http: HttpClient) {}

  // Handles request timeout 
  // https://stackoverflow.com/questions/45938931/default-and-specific-request-timeout
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const timeoutValue = req.headers.get('timeout') || this.defaultTimeout;
    const timeoutValueNumeric = Number(timeoutValue);

    return next.handle(req).pipe(timeout(timeoutValueNumeric));
  }

  private url: string = 'api/';
  private httpHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))

  })
  

  public getAmountPresent() {
      this.httpHeader = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))
    
      })
    
    let uri = this.url + 'children/allPresence';
    return this.http.get(uri, {headers: this.httpHeader})
  }

  // presence list
  public getAllChildren() : Observable<any> {
    let uri = this.url + 'children/all';
    
    return this.http.get(uri, {headers: this.httpHeader});
  }

  // qr-code scanning
  public getChildByID(ID: string) {
    let uri = this.url + 'children/getchild';
    let body = {'id': ID};
    return this.http.post(uri, body, {headers: this.httpHeader});
  }

  public getChildByPsn(psn: number,name: string) {
    let uri = this.url + 'children/getchildbypsn';
    let body = {'id': psn, 'name': name };
    return this.http.post(uri, body, {headers: this.httpHeader});
  }

  public getPDFOutput(): Observable<any> {
    let uri = this.url + 'upload/downloadoutput';
    return this.http.get(uri, {headers: this.httpHeader});
  }

  public updateChildPresencebyid(id: string, name: string, presence: boolean) {
    let uri = this.url + 'children/id'
    let body = {'id': id, 'name': name, 'presence': presence}
    return this.http.put(uri, body, {headers: this.httpHeader})
  }

  public updateChildPresencebypsn(id: string, name: string, presence: boolean) {
    let uri = this.url + 'children/psn'
    let body = {'id': id, 'name': name, 'presence': presence}
    return this.http.put(uri, body, {headers: this.httpHeader})
  }

  public loginUser(username: string, password: string) : Observable<any> {
    let uri = this.url + 'auth/login'
    let body = {"username": username, "password": password}
    return this.http.post(uri, body, {headers: this.httpHeader})
  }

  public addChild(name: string, presence: boolean, level: string, group: string, meetingpoint: string, comment: string) : Observable<Object> {
    let uri = this.url + 'children/addchild'
    let body = {"name" : name, "presence" : presence, "level" : level, "group" : group, "meetingpoint" : meetingpoint, "comment" : comment}
    return this.http.post(uri, body, {headers: this.httpHeader})
  }
}
